<template>
  <div class="pdf-recommendation-c">
    <vue-pdf-app 
      :pdf="sourceRecomC"
      theme="light" 
      class="pdfTemp" 
      :config="config"
      pageScale="auto"
      :page-number="1"
    ></vue-pdf-app>
    <v-btn 
      color="primary"
      :min-width="120"
      class="mt-4"
      large
      :disabled="!url"
      @click="fillForm()"
    >
      CETAK
    </v-btn>
  </div>
</template>

<script>
import { get } from 'dot-prop'
import moment from 'moment'
import { PDFDocument } from 'pdf-lib'
import downloadjs from "downloadjs"
import "vue-pdf-app/dist/icons/main.css"
import VuePdfApp from "vue-pdf-app"
import { tipe_c_1, tipe_c_mth } from '@/constants/pdfRecommendContent'
import request from '@/utils/request'
export default {
  data () {
    return {
      sourceRecomC: '',
      config: {
        toolbar: {
          toolbarViewerRight: {
            presentationMode: false,
            openFile: false,
            print: false,
            download: false,
            viewBookmark: false,
          },
          toolbarViewerLeft: { 
            findbar: false,
            previous: true,
            next: true,
            pageNumber: true,
          }
        },
      },
      url: '',
      formData: []
    }
  },
  components: {
    VuePdfApp
  },
  computed: {
    getCompletionForm () {
      const data = get(this.$store.state, 'recommendation_form_result', [])
      const formData = data.filter(itm => itm.type === 'c')[0] || {}
      formData.recommendation_other_person && formData.recommendation_other_person.map(itm => {
        formData[`name-${itm.attr}`] = itm.name
        formData[`address-${itm.attr}`] = itm.address
        formData[`phone-${itm.attr}`] = itm.phone
      })
      return formData
    },
    isNewVersion () {
      const version = get(this.getCompletionForm, 'version', 'v2') 
      return version === 'v2'
    }
  },
  created () {
    this.getConfigData()
    this.sourceRecomC = this.url
  },
  async mounted() {
    const formUrl = this.url
    if (formUrl) {
      const formPdfBytes = await fetch(this.url).then(res => res.arrayBuffer())
      const pdfDoc = await PDFDocument.load(formPdfBytes)
      if (this.getCompletionForm) {
        const signPictUrl = this.getCompletionForm.sign_pict_url || ''
        const signPictBytes = await fetch(signPictUrl).then(res => res.arrayBuffer())
    
        let sign_pict_url = ''

        try {
          sign_pict_url = signPictUrl ? await pdfDoc.embedPng(signPictBytes) : ''
        } catch (error) {
          sign_pict_url = signPictUrl ? await pdfDoc.embedJpg(signPictBytes) : ''
        }

        const pictImage = {
          sign_pict_url: sign_pict_url,
        }
    
        const form = pdfDoc.getForm()
        document.getElementById("password").id = 'passwordIdC'
        document.getElementById("findInput").id = 'findInputIdC'

        // const fields = form.getFields()
        // fields.forEach(field => {
        //   const type = field.constructor.name
        //   const name = field.getName()
        //   if (type === 'PDFTextField') {
        //     console.log(`${type}: ${name}`)
        //   } else if (type === 'PDFRadioGroup') {
        //     console.log(`${type}: ${name}`)
        //     console.log(form.getRadioGroup(field.getName()).getOptions())
        //   } else {
        //     console.log(`${type}: ${name}`)
        //   }
        // })
        
        // fill completion data
        this.formData.map(async item => {
          if (item.type === 'file') {
            form.getButton(item.id).setImage(pictImage[item.fields])
          } else if (item.type === 'text') {
            form.getTextField(item.id).setText(this.getCompletionForm[item.fields])
          } else if (item.type === 'select') {
            item.option.map(itm => {
              if (itm === this.getCompletionForm[item.fields]) {
                form.getDropdown(item.id).select(itm)
              }
            })
          } else if (item.type === 'date') {
            this.getCompletionForm[item.fields] > 0 && form.getDropdown(item.id).select(moment(new Date(this.getCompletionForm[item.fields]*1000)).locale('id').format('DD'))
          } else if (item.type === 'month') {
            this.getCompletionForm[item.fields] > 0 && form.getDropdown(item.id).select(moment(new Date(this.getCompletionForm[item.fields]*1000)).locale('id').format('MMMM'))
          } else if (item.type === 'year') {
            this.getCompletionForm[item.fields] > 0 && form.getTextField(item.id).setText(moment(new Date(this.getCompletionForm[item.fields]*1000)).locale('id').format('YYYY'))
          } else if (item.type === 'radio') {
            item.options.map(itm => {
              if (itm.value === this.getCompletionForm[item.fields]) {
                const value = form.getRadioGroup(item.id).acroField.getOnValues()
                form.getRadioGroup(item.id).acroField.setValue(value[itm.select])
              }
            })
          } else if (item.type === 'checkbox') {
            this.getCompletionForm[item.fields] === true ? form.getCheckBox(item.id).check() : form.getCheckBox(item.id).uncheck()
          }
        })
      }
      const pdfBytes = await pdfDoc.save()
      this.sourceRecomC = pdfBytes.buffer
    }
  },
  methods: {
    getConfigData () {
      const prodi = get(this.$store.state, 'studentDetail.program_study', 'sth')
      if (prodi === 'sth') {
        this.formData = []
        this.url = ''
      } else if (prodi === 'mth') {
        this.formData = this.isNewVersion ? tipe_c_mth : tipe_c_1
        this.url = this.isNewVersion ? 'https://webadmisi-sttaa.sgp1.digitaloceanspaces.com/registration-form/V2/form-recommendation-c-mth.pdf' : 'https://webadmisi-sttaa.sgp1.digitaloceanspaces.com/registration-form/form-recommendation-mth-c.pdf'
      } else if (prodi === 'mmin') {
        this.formData = []
        this.url = ''
      } else if (prodi === 'mdiv') {
        this.formData = []
        this.url = ''
      } else if (prodi === 'sert') {
        this.formData = []
        this.url = ''
      }
    },
    fillForm () {
      downloadjs(this.sourceRecomC, "form-recommendation-a.pdf", "application/pdf");
    }
  }
}
</script>

<style lang="scss" scoped>
.pdf-recommendation-c {
  .pdfTemp {
    height: 800px;
    ::v-deep .toolbar {
      z-index: 0;
    }
  }
}
</style>